import React, { useState, useEffect } from "react";
import { getState, getOrdinalSuffix } from "../Helpers";

export const OffbeatTouristAttractionsStats = ({ city, state }) => {
  const [error, setError] = useState("");
  const [stateRank, setStateRank] = useState(0);
  const [total, setTotal] = useState(0);
  const full_state = getState(state);
  const full_city = city;
  const full_city_state = full_city
    ? full_city + ", " + full_state
    : full_state;

  useEffect(() => {
    const GetStateRankData = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT +
          "/api/ota-ranking?state=" +
          state +
          "&city=" +
          city,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
            console.log("OTAStateRankDataError", response);
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.length > 0) {
            // Get the state rank and total number of sightings
            setTotal(data[0].number);
            setStateRank(data[0].rank);
          }

          //console.log(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("OTAStateRankDataError", error);
        });
    };

    GetStateRankData();
  }, [city, state]);

  return (
    <div className="stat">
      {error !== "" ? (
        <div className="error">{error}</div>
      ) : (
        <>
          <h2>Is {full_city_state} a good place for a road trip?</h2>

          <ul>
            <li>
              <span>{full_city_state}</span> currently ranks{" "}
              <span>
                {stateRank}
                <sup>{getOrdinalSuffix(stateRank)}</sup>
              </span>{" "}
              in the number of offbeat places, with{" "}
              <span>{total > 0 ? total.toLocaleString("en-US") : "no"}</span>{" "}
              known offbeat tourist attraction{total !== 1 ? "s" : null}.
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default OffbeatTouristAttractionsStats;
