import React from "react";
import { useParams } from "react-router-dom";
import { BigfootStats } from "./stats/BigfootStats";
import { DogmanStats } from "./stats/DogmanStats";
import { UfoStats } from "./stats/UfoStats";
import { HauntedPlacesStats } from "./stats/HauntedPlacesStats";
import { FamousGravesStats } from "./stats/FamousGravesStats";
import { OffbeatTouristAttractionsStats } from "./stats/OffbeatTouristAttractionsStats";
import { getState } from "./Helpers";
import { Helmet } from "react-helmet";

const City = () => {
  const { city, state } = useParams();

  return (
    <div className="state-page">
      <Helmet>
        <title>
          {city}, {getState(state)} UFO & Bigfoot Sightings & Oddities
        </title>
      </Helmet>
      <div className="state">
        <h1>
          {city}, {getState(state)} UFO & Bigfoot Sightings & Oddities
        </h1>

        <BigfootStats city={city} state={state} />
        <DogmanStats city={city} state={state} />
        <UfoStats city={city} state={state} />
        <HauntedPlacesStats city={city} state={state} />
        <FamousGravesStats city={city} state={state} />
        <OffbeatTouristAttractionsStats city={city} state={state} />
      </div>
    </div>
  );
};

export default City;
