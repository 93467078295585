import React, { useState, useEffect } from "react";
import { getState, getOrdinalSuffix } from "../Helpers";
import { LineGraph } from "../LineGraph";

export const UfoStats = ({ city, state }) => {
  const [error, setError] = useState("");
  const [sightingData, setSightingData] = useState(null);
  const [sightingsLast5Years, setSightingsLast5Years] = useState("");
  const [mostRecentSightingYear, setMostRecentSightingYear] = useState(null);
  const [sightingsMostRecentYear, setSightingsMostRecentYear] = useState(0);
  const [sightingsMostRecentYearPrior, setSightingsMostRecentYearPrior] =
    useState(0);
  const [totalSightings, setTotalSightings] = useState(0);
  const [stateRank, setStateRank] = useState(0);
  const [peakYear, setPeakYear] = useState(null);
  const [peakYearSightings, setPeakYearSightings] = useState(0);
  const [lineData, setLineData] = useState([]);
  const [minYear, setMinYear] = useState(0);
  const [maxSightings, setMaxSightings] = useState(0);
  const full_state = getState(state);
  const full_city = city;
  const full_city_state = full_city
    ? full_city + ", " + full_state
    : full_state;

  useEffect(() => {
    const GetSightingData = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT +
          "/api/ufo-data?state=" +
          state +
          "&city=" +
          city,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
            console.log("UfoSightingDataError", response);
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.length > 0) {
            // Get the year of the most recent sighting
            const year_recent = Math.max(...data.map((o) => o.year));

            // Get the min year
            const year_min = Math.min(...data.map((o) => o.year));

            // Get the max number of sightings in a year
            const max_sightings = Math.max(
              ...data.map((o) => o.number_of_sightings)
            );

            // Get the number of sightings in 5 years prior to the most recent year
            const sightings_last_5 = data
              .filter(({ year }) => year >= year_recent - 5)
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Get the number of sightings for the most recent year
            const sightings_recent = data
              .filter(({ year }) => year === year_recent)
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Get the number of sightings in the year prior to the most recent year
            const sightings_recent_prior = data
              .filter(({ year }) => year === year_recent - 1)
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Get the element with the most sightings
            const most_sightings = data.reduce((prev, current) => {
              return prev.number_of_sightings > current.number_of_sightings
                ? prev
                : current;
            });

            // Build the line graph data
            const line_data = [
              {
                id: "UFO Sightings",
                color: "#62CDBB",
                data: data.map((element) => ({
                  x: element.year,
                  y: element.number_of_sightings,
                })),
              },
            ];

            // Set the state with all of the data
            setMostRecentSightingYear(year_recent);
            setMinYear(year_min);
            setMaxSightings(max_sightings);
            setSightingsLast5Years(sightings_last_5);
            setSightingsMostRecentYear(sightings_recent);
            setSightingsMostRecentYearPrior(sightings_recent_prior);
            setPeakYear(most_sightings.year);
            setPeakYearSightings(most_sightings.number_of_sightings);
            setLineData(line_data);
          }

          setSightingData(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("UfoSightingDataError", error);
        });
    };

    const GetStateRankData = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT +
          "/api/ufo-ranking?state=" +
          state +
          "&city=" +
          city,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
            console.log("UfoStateRankDataError", response);
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.length > 0) {
            // Get the state rank and total number of sightings
            setTotalSightings(data[0].number_of_sightings);
            setStateRank(data[0].rank);
          }

          //console.log(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("UfoStateRankDataError", error);
        });
    };

    GetSightingData();
    GetStateRankData();
  }, [city, state]);

  return (
    <div className="stat">
      {error !== "" ? (
        <div className="error">{error}</div>
      ) : (
        <>
          <h2>{full_city_state} Residents Look to the Sky</h2>
          {sightingData && sightingData.length > 0 ? (
            <>
              {lineData ? (
                <div className="graph">
                  <LineGraph
                    data={lineData}
                    min_year={minYear}
                    max_year={mostRecentSightingYear}
                    min_sightings={0}
                    max_sightings={maxSightings}
                  />
                </div>
              ) : null}

              <ul>
                <li>
                  For <span>{full_city_state}'s</span> most recently available
                  data (<span>{mostRecentSightingYear}</span>), there were{" "}
                  <span>{sightingsMostRecentYear.toLocaleString("en-US")}</span>{" "}
                  UFO sighting{sightingsMostRecentYear !== 1 ? "s" : null}. This
                  was{" "}
                  <span>
                    {sightingsMostRecentYear === sightingsMostRecentYearPrior
                      ? "the same as"
                      : sightingsMostRecentYear > sightingsMostRecentYearPrior
                      ? "above"
                      : "below"}
                  </span>{" "}
                  the prior year's number of{" "}
                  <span>
                    {sightingsMostRecentYearPrior.toLocaleString("en-US")}
                  </span>{" "}
                  UFO sighting{sightingsMostRecentYearPrior !== 1 ? "s" : null}.
                  It was{" "}
                  <span>
                    {sightingsMostRecentYear ===
                    Math.round(sightingsLast5Years / 5)
                      ? "the same as"
                      : sightingsMostRecentYear >
                        Math.round(sightingsLast5Years / 5)
                      ? "above"
                      : "below"}
                  </span>{" "}
                  the 5 year average of{" "}
                  <span>
                    {Math.round(sightingsLast5Years / 5).toLocaleString(
                      "en-US"
                    )}
                  </span>
                  .
                </li>
                <li>
                  The last reported UFO sighting in{" "}
                  <span>{full_city_state}</span> was in{" "}
                  <span>{mostRecentSightingYear}</span>.
                </li>
                <li>
                  <span>{full_city_state}</span> currently ranks{" "}
                  <span>
                    {stateRank}
                    <sup>{getOrdinalSuffix(stateRank)}</sup>
                  </span>{" "}
                  in the number of UFO sightings, with{" "}
                  <span>{totalSightings.toLocaleString("en-US")}</span> reported
                  UFO sighting{totalSightings !== 1 ? "s" : null}.
                </li>
                <li>
                  The peak year for UFO sightings was <span>{peakYear}</span>,
                  with <span>{peakYearSightings.toLocaleString("en-US")}</span>{" "}
                  reported sighting{peakYearSightings !== 1 ? "s" : null}.
                </li>
              </ul>
            </>
          ) : sightingData && sightingData.length === 0 ? (
            <div className="no-data">
              There have been no dated UFO sightings in {full_city_state}.
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default UfoStats;
