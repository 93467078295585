import React, { useState, useEffect } from "react";

const PageNotFound = () => {
  const [num, setNum] = useState(0);
  const [messages, setMessages] = useState([]);

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    setMessages([
      "Well, this is embarassing.",
      "Hmmmm....  Something appears to be missing.",
    ]);
    setNum(randomNumberInRange(0, 1));
  }, []);

  return (
    <div className="page-not-found">
      <h1>{messages[num]}</h1>
      <h2>
        You appear to be a little lost. Go back to the <a href="/">homepage</a>{" "}
        and try this again.
      </h2>
    </div>
  );
};

export default PageNotFound;
