import { useEffect, useState } from "react";

export const useTheme = () => {
  const [theme, setTheme] = useState("light");
  const [componentMounted, setComponentMounted] = useState(false);

  const setMode = (mode) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  useEffect(() => {
    // Get the theme stored in local storage
    const localTheme = window.localStorage.getItem("theme");

    // Set the theme
    if (localTheme) {
      setTheme(localTheme);
    } else {
      setMode("light");
    }

    // Set mounted to true so the page can render
    setComponentMounted(true);
  }, []);

  return [theme, toggleTheme, componentMounted];
};
