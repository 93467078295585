import React, { useState } from "react";
import { StyledSearchBox } from "./styled/StyledSearchBox";
import cancelSearch from "../icons/x.png";
import cancelSearchWhite from "../icons/x-white.png";

export default function SearchBox({ theme, handleSearchTermsChanged }) {
  const [searchTerms, setSearchTerms] = useState("");
  const [currentValue, setCurrentValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchTerms(event.target.value);
      handleSearchTermsChanged(event.target.value);
    }
  };

  return (
    <StyledSearchBox>
      <input
        type="text"
        placeholder="Search"
        value={currentValue}
        onKeyDown={handleKeyDown}
        onChange={(e) => setCurrentValue(e.target.value)}
      />
      {searchTerms !== "" ? (
        <button
          onClick={() => {
            handleSearchTermsChanged("");
            setSearchTerms("");
            setCurrentValue("");
          }}
        >
          <img
            src={theme === "light" ? cancelSearch : cancelSearchWhite}
            alt="Cancel Search"
          />
        </button>
      ) : null}
    </StyledSearchBox>
  );
}
