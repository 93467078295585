import { useContext } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ThemeContext } from "styled-components";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const LineGraph = ({
  theme,
  data,
  min_year,
  max_year,
  sighting_ticks,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 0, bottom: 110, left: 50 }}
      xScale={{ type: "linear", min: min_year, max: max_year }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      colors={{ datum: "color" }}
      yFormat=" >-.0c"
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -35,
        tickValues: 10,
        legend: "Years",
        legendOffset: 55,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: sighting_ticks,
        legend: "Sightings",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: themeContext.chartLegendTextColor,
            },
          },
          legend: {
            text: {
              fontWeight: "bold",
              fill: themeContext.chartLegendTextColor,
            },
          },
        },
        legends: { text: { fill: themeContext.chartLegendTextColor } },
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#1d1e25",
            fontSize: 14,
          },
        },
        grid: {
          line: {
            stroke: themeContext.chartGridColor,
          },
        },
        crosshair: {
          line: {
            stroke: themeContext.chartCrosshairColor,
            strokeWidth: 1,
            strokeOpacity: 0.8,
          },
        },
      }}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
              color: "#000",
            }}
          >
            <div
              style={{
                fontFamily: "Amatic SC",
                fontWeight: 700,
                fontSize: "30px",
                textAlign: "center",
              }}
            >
              {slice.points[0].data.x}
            </div>
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  padding: "3px 0",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    background: point.serieColor,
                  }}
                ></span>
                <strong>{point.serieId} Sightings</strong>:{"  "}
                {point.data.yFormatted}
              </div>
            ))}
          </div>
        );
      }}
      enablePoints={false}
      lineWidth={3}
      pointSize={7}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 105,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
