import React from 'react';
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import PageNotFound from './components/PageNotFound'
import MapPage from './components/MapPage'
import Details from './components/Details'
import Credits from './components/Credits'
import States from './components/States'
import State from './components/State'
import City from './components/City'
import FAQ from './components/FAQ'
import { BrowserRouter, useRoutes } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './components/GlobalStyles';
import { useTheme } from './components/useTheme';
import { useToggleFilters } from './components/useToggleFilters';

const AppRoutes = ({theme, filtersToggleEvent, toggleFilters }) =>
  useRoutes([
    { path: "/details/:lat/:lng/:type", element: <Details theme={theme} /> },
    { path: "/faq", element: <FAQ /> },
    { path: "/credits", element: <Credits /> },
    { path: "/states", element: <States /> },
    { path: "/state/:state", element: <State /> },
    { path: "/:state/city/:city", element: <City /> },
    { path: "/", element: <MapPage theme={theme} filtersToggleEvent={filtersToggleEvent} toggleFilters={toggleFilters} /> },
    { path: "*", element: <PageNotFound /> }
]);

const App = () => {
  const [theme, toggleTheme, componentMounted] = useTheme();
  const [filtersToggleEvent, toggleFilters] = useToggleFilters();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />
  };

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <div className="app">
        <div className="site">
        <Header theme={theme} toggleTheme={toggleTheme} />
          <BrowserRouter>
            <AppRoutes theme={theme} filtersToggleEvent={filtersToggleEvent} toggleFilters={toggleFilters} />
          </BrowserRouter>
          <Footer />
        </div>
      </div>
    </ThemeProvider>);
}

export default App;
