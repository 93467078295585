import { useState } from "react";

export const useToggleFilters = () => {
  const [filtersToggleEvent, setFiltersToggleEvent] = useState(0);

  const toggleFilters = () => {
    console.log("toggleFilters");
    setFiltersToggleEvent(Date.now());
  };

  return [filtersToggleEvent, toggleFilters];
};
