import React from "react";
import { FilterButton } from "./styled/FilterButton";

export default function ToggleFilter({ toggleFilters }) {
  return (
    <FilterButton
      className="filter-button"
      onClick={toggleFilters}
      aria-label="Toggle Filters"
    >
      <svg viewBox="0 0 100 80" width="20" height="15">
        <rect width="100" height="10"></rect>
        <rect x="14" y="33" width="70" height="10"></rect>
        <rect x="29" y="65" width="40" height="10"></rect>
      </svg>
    </FilterButton>
  );
}
