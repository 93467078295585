import React from "react";
import SlideToggle from "react-slide-toggle";
import SearchBox from "./SearchBox";
import ToggleFilter from "./ToggleFilter";

const FilterBlock = (props) => {
  return (
    <div className="filter-block-wrapper">
      <SlideToggle
        duration={200}
        toggleEvent={props.filtersToggleEvent}
        collapsed={true}
      >
        {({ setCollapsibleElement }) => (
          <div className="filters-collapsible">
            <div
              className="my-collapsible__content"
              ref={setCollapsibleElement}
            >
              <div className="filter-wrapper">
                <div className="my-collapsible__content-inner">
                  <div className="filter-block">
                    <div className="filters">
                      {props.searchResultsCount === 0 ? (
                        <div className="no-results">No results found</div>
                      ) : null}

                      <div className="filter">
                        <h2>Search</h2>
                        <SearchBox
                          theme={props.theme}
                          handleSearchTermsChanged={
                            props.handleSearchTermsChanged
                          }
                        />
                      </div>
                      <div className="filter">
                        <h2>Find</h2>
                        <label className="checkbox-control">
                          <input
                            type="checkbox"
                            checked={props.filters.bigfoot}
                            onChange={props.handleBigfootChange}
                          />
                          Bigfoot Sightings
                        </label>
                        <label className="checkbox-control">
                          <input
                            type="checkbox"
                            checked={props.filters.dogman}
                            onChange={props.handleDogmanChange}
                          />
                          Dogman Sightings
                        </label>
                        <label className="checkbox-control">
                          <input
                            type="checkbox"
                            checked={props.filters.ufo}
                            onChange={props.handleUfoChange}
                          />
                          UFO Sightings
                        </label>
                        <label className="checkbox-control">
                          <input
                            type="checkbox"
                            checked={props.filters.hauntedplaces}
                            onChange={props.handleHauntedPlacesChange}
                          />
                          Haunted Places
                        </label>
                        <label className="checkbox-control">
                          <input
                            type="checkbox"
                            checked={props.filters.famousgraves}
                            onChange={props.handleFamousGravesChange}
                          />
                          Famous Graves
                        </label>
                        <label className="checkbox-control">
                          <input
                            type="checkbox"
                            checked={props.filters.offbeattouristattractions}
                            onChange={
                              props.handleOffbeatTouristAttractionsChange
                            }
                          />
                          Offbeat Attractions
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </SlideToggle>
      <div className="toggle">
        <ToggleFilter toggleFilters={props.toggleFilters} />
      </div>
    </div>
  );
};

export default FilterBlock;
