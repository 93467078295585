import React from "react";
import { Helmet } from "react-helmet";

const Credits = () => {
  return (
    <div className="credits">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <h1>Credits</h1>

      <ul>
        <li className="data">
          Haunted Places data from{" "}
          <a href="http://www.theshadowlands.net/places/">
            The Shadowlands Haunted Places Index
          </a>{" "}
          that was compiled and geo-coded by Tim Renner
        </li>
        <li className="data">
          Bigfoot data from the Bigfoot Field Researchers Organization (BFRO),
          compiled by Tim Renner
        </li>
        <li className="data">
          UFO data from the National UFO Research Center (NUFORC), compiled and
          geo-coded by Tim Renner
        </li>
        <li className="data">
          Dogman data from North American Dogman Project, compiled by Tim Renner
        </li>
        <li className="data">Famous grave data from POI Factory</li>
        <li className="data">
          Offbeat tourist attraction data from POI Factory
        </li>
        <li>
          <a
            href="https://www.flaticon.com/free-icons/bigfoot"
            title="bigfoot icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Bigfoot icons created by Freepik - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/ufo"
            title="ufo icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Ufo icons created by Freepik - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/werewolf"
            title="werewolf icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Werewolf icons created by Umeicon - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/ghost"
            title="ghost icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Ghost icons created by Freepik - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/mystery"
            title="mystery icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Mystery icons created by Freepik - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/sun"
            title="sun icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Sun icons created by Good Ware - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/moon"
            title="moon icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Moon icons created by Freepik - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/search"
            title="search icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Search icons created by Smashicons - Flaticon
          </a>
        </li>

        <li>
          <a
            href="https://www.flaticon.com/free-icons/x"
            title="x icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            X icons created by Stockio - Flaticon
          </a>
        </li>
        <li>
          <a
            href="https://www.flaticon.com/free-icons/grave"
            title="grave icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Grave icons created by kosonicon - Flaticon
          </a>
        </li>
        <li>
          <a
            href="https://www.flaticon.com/free-icons/direction"
            title="direction icons"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Direction icons created by narak0rn - Flaticon
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Credits;
