import React, { useState, useEffect } from "react";
import { getState, getOrdinalSuffix } from "../Helpers";
import { LineGraph } from "../LineGraph";

export const BigfootStats = ({ city, state }) => {
  const [error, setError] = useState("");
  const [bfSightingData, setBFSightingData] = useState(null);
  const [bfSightingsLast5Years, setBFSightingsLast5Years] = useState("");
  const [bfMostRecentSightingYear, setBFMostRecentSightingYear] =
    useState(null);
  const [bfSightingsMostRecentYear, setBFSightingsMostRecentYear] = useState(0);
  const [bfSightingsMostRecentYearPrior, setBFSightingsMostRecentYearPrior] =
    useState(0);
  const [bfTotalSightings, setBFTotalSightings] = useState(0);
  const [bfStateRank, setBFStateRank] = useState(0);
  const [bfPeakYear, setBFPeakYear] = useState(null);
  const [bfPeakYearSightings, setBFPeakYearSightings] = useState(0);
  const [bfClassASightings, setBFClassASightings] = useState(0);
  const [bfClassBSightings, setBFClassBSightings] = useState(0);
  const [bfClassCSightings, setBFClassCSightings] = useState(0);
  const [bfLineData, setBFLineData] = useState([]);
  const [bfMinYear, setBFMinYear] = useState(0);
  const [bfMaxSightings, setBFMaxSightings] = useState(0);
  const full_state = getState(state);
  const full_city = city;
  const full_city_state = full_city
    ? full_city + ", " + full_state
    : full_state;

  useEffect(() => {
    const GetBigfootSightingData = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT +
          "/api/bf-data?state=" +
          state +
          "&city=" +
          city,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
            console.log("BFSightingDataError", response);
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.length > 0) {
            // Get the year of the most recent sighting
            const year_recent = Math.max(...data.map((o) => o.year));

            // Get the min year
            const bf_year_min = Math.min(...data.map((o) => o.year));

            // Get the max number of sightings in a year
            const bf_max_sightings = Math.max(
              ...data.map((o) => o.number_of_sightings)
            );

            // Get the number of sightings in 5 years prior to the most recent year
            const sightings_last_5 = data
              .filter(({ year }) => year >= year_recent - 5)
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Get the number of sightings for the most recent year
            const sightings_recent = data
              .filter(({ year }) => year === year_recent)
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Get the number of sightings in the year prior to the most recent year
            const sightings_recent_prior = data
              .filter(({ year }) => year === year_recent - 1)
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Combine all classifications together so the peak number can be found
            const bf_combined = data.reduce(function (pV, cV, cI) {
              var index = pV.findIndex((item) => item["year"] === cV["year"]);

              if (index >= 0) {
                pV[index]["number_of_sightings"] =
                  pV[index]["number_of_sightings"] + cV["number_of_sightings"];
              } else {
                pV.push({
                  year: cV["year"],
                  number_of_sightings: cV["number_of_sightings"],
                });
              }
              return pV; // *********  Important ******
            }, []);

            //console.log(bf_combined);

            // Get the element with the most sightings
            const bf_most_sightings = bf_combined.reduce((prev, current) => {
              return prev.number_of_sightings > current.number_of_sightings
                ? prev
                : current;
            });

            // Get the count of Class A, B and C sightings
            const class_a_sightings = data
              .filter(({ classification }) => classification === "Class A")
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            const class_b_sightings = data
              .filter(({ classification }) => classification === "Class B")
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            const class_c_sightings = data
              .filter(({ classification }) => classification === "Class C")
              .reduce((total, obj) => obj.number_of_sightings + total, 0);

            // Build the line graph data
            const bf_line_data = [
              {
                id: "Class C",
                color: "#F4E478",
                data: data
                  .filter(({ classification }) => classification === "Class C")
                  .map((element) => ({
                    x: element.year,
                    y: element.number_of_sightings,
                  })),
              },
              {
                id: "Class B",
                color: "#F47560",
                data: data
                  .filter(({ classification }) => classification === "Class B")
                  .map((element) => ({
                    x: element.year,
                    y: element.number_of_sightings,
                  })),
              },
              {
                id: "Class A",
                color: "#62CDBB",
                data: data
                  .filter(({ classification }) => classification === "Class A")
                  .map((element) => ({
                    x: element.year,
                    y: element.number_of_sightings,
                  })),
              },
            ];

            // Set the state with all of the data
            setBFMostRecentSightingYear(year_recent);
            setBFMinYear(bf_year_min);
            setBFMaxSightings(bf_max_sightings);
            setBFSightingsLast5Years(sightings_last_5);
            setBFSightingsMostRecentYear(sightings_recent);
            setBFSightingsMostRecentYearPrior(sightings_recent_prior);
            setBFPeakYear(bf_most_sightings.year);
            setBFPeakYearSightings(bf_most_sightings.number_of_sightings);
            setBFClassASightings(class_a_sightings);
            setBFClassBSightings(class_b_sightings);
            setBFClassCSightings(class_c_sightings);
            setBFLineData(bf_line_data);
            console.log(bf_line_data);
          }

          console.log(data.length);

          setBFSightingData(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("BFSightingDataError", error);
        });
    };

    const GetBigfootStateRankData = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "/api/bf-ranking?state=" + state,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
            console.log("BFStateRankDataError", response);
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.length > 0) {
            // Get the state rank and total number of sightings
            setBFTotalSightings(data[0].number_of_sightings);
            setBFStateRank(data[0].rank);
          }

          //console.log(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("BFStateRankDataError", error);
        });
    };

    GetBigfootSightingData();
    GetBigfootStateRankData();
  }, [city, state]);

  return (
    <div className="stat">
      {error !== "" ? (
        <div className="error">{error}</div>
      ) : (
        <>
          <h2>Are there Bigfoot in {full_city_state}?</h2>
          {bfSightingData && bfSightingData.length > 0 ? (
            <>
              {bfLineData ? (
                <div className="graph">
                  <LineGraph
                    data={bfLineData}
                    min_year={bfMinYear}
                    max_year={bfMostRecentSightingYear}
                    min_sightings={0}
                    max_sightings={bfMaxSightings}
                    sighting_ticks={bfMaxSightings}
                  />
                </div>
              ) : null}

              <ul>
                <li>
                  For <span>{full_city_state}'s</span> most recently available
                  data (<span>{bfMostRecentSightingYear}</span>
                  ), there were{" "}
                  <span>
                    {bfSightingsMostRecentYear.toLocaleString("en-US")}
                  </span>{" "}
                  bigfoot sighting{bfSightingsMostRecentYear !== 1 ? "s" : null}
                  . This was{" "}
                  <span>
                    {bfSightingsMostRecentYear ===
                    bfSightingsMostRecentYearPrior
                      ? "the same as"
                      : bfSightingsMostRecentYear >
                        bfSightingsMostRecentYearPrior
                      ? "above"
                      : "below"}
                  </span>{" "}
                  the prior year's number of{" "}
                  <span>
                    {bfSightingsMostRecentYearPrior.toLocaleString("en-US")}
                  </span>{" "}
                  bigfoot sighting
                  {bfSightingsMostRecentYearPrior !== 1 ? "s" : null}. It was{" "}
                  <span>
                    {bfSightingsMostRecentYear ===
                    Math.round(bfSightingsLast5Years / 5)
                      ? "the same as"
                      : bfSightingsMostRecentYear >
                        Math.round(bfSightingsLast5Years / 5)
                      ? "above"
                      : "below"}
                  </span>{" "}
                  the 5 year average of{" "}
                  <span>
                    {Math.round(bfSightingsLast5Years / 5).toLocaleString(
                      "en-US"
                    )}
                  </span>
                  .
                </li>
                <li>
                  Of <span>{bfTotalSightings.toLocaleString("en-US")}</span>{" "}
                  bigfoot sighting{bfTotalSightings !== 1 ? "s" : null},{" "}
                  <span>
                    {Math.round(
                      100 * (bfClassASightings / bfTotalSightings)
                    ).toLocaleString("en-US")}
                    %
                  </span>{" "}
                  have been viewed as moderately credible (Class A),{" "}
                  <span>
                    {Math.round(
                      100 * (bfClassBSightings / bfTotalSightings)
                    ).toLocaleString("en-US")}
                    %
                  </span>{" "}
                  have been viewed as somewhat credible (Class B), and{" "}
                  <span>
                    {Math.round(
                      100 * (bfClassCSightings / bfTotalSightings)
                    ).toLocaleString("en-US")}
                    %
                  </span>{" "}
                  have low credibility (Class C).
                </li>
                <li>
                  The last reported bigfoot sighting in{" "}
                  <span>{full_city_state}</span> was in{" "}
                  <span>{bfMostRecentSightingYear}</span>.
                </li>
                <li>
                  <span>{full_city_state}</span> currently ranks{" "}
                  <span>
                    {bfStateRank}
                    <sup>{getOrdinalSuffix(bfStateRank)}</sup>
                  </span>{" "}
                  in the number of bigfoot sightings, with{" "}
                  <span>{bfTotalSightings.toLocaleString("en-US")}</span>{" "}
                  reported bigfoot sighting{bfTotalSightings !== 1 ? "s" : null}
                  .
                </li>
                <li>
                  The peak year for bigfoot sightings was{" "}
                  <span>{bfPeakYear}</span>, with{" "}
                  <span>{bfPeakYearSightings.toLocaleString("en-US")}</span>{" "}
                  reported sighting{bfPeakYearSightings !== 1 ? "s" : null}.
                </li>
              </ul>
            </>
          ) : bfSightingData && bfSightingData.length === 0 ? (
            <div className="no-data">
              There have been no dated bigfoot sightings in {full_city_state}.
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default BigfootStats;
