import React from "react";

const Footer = (props) => {
  return (
    <footer>
      <ul>
        <li className="copyright">
          <span>&copy;</span> MapSquatch.com
        </li>
        <li>
          <a href="/states">States</a>
        </li>
        <li>
          <a href="/faq">FAQ</a>
        </li>
        <li>
          <a href="/credits">Credits</a>
        </li>
        <li>
          <a href="mailto:contact@mapsquatch.com">Contact</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
