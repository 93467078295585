import React from "react";
import { useParams } from "react-router-dom";
import { BigfootStats } from "./stats/BigfootStats";
import { DogmanStats } from "./stats/DogmanStats";
import { UfoStats } from "./stats/UfoStats";
import { HauntedPlacesStats } from "./stats/HauntedPlacesStats";
import { FamousGravesStats } from "./stats/FamousGravesStats";
import { OffbeatTouristAttractionsStats } from "./stats/OffbeatTouristAttractionsStats";
import { Cities } from "./stats/Cities";
import { getState } from "./Helpers";
import { Helmet } from "react-helmet";

const State = () => {
  const { state } = useParams();

  return (
    <div className="state-page">
      <Helmet>
        <title>{getState(state)} UFO & Bigfoot Sightings & Oddities</title>
      </Helmet>

      <div className="state">
        <h1>{getState(state)} UFO & Bigfoot Sightings & Oddities</h1>

        <BigfootStats state={state} />
        <DogmanStats state={state} />
        <UfoStats state={state} />
        <HauntedPlacesStats state={state} />
        <FamousGravesStats state={state} />
        <OffbeatTouristAttractionsStats state={state} />
        <Cities state={state} />
      </div>
    </div>
  );
};

export default State;
