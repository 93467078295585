// a0522d
// 5D7D54
export const lightTheme = {
  backgroundColor: "#fff", 
  color: "#1d1e25",

  popupLinkColor: "#00887a",
  popupLinkHoverColor: "#1d1e25",

  toggleBorder: "transparent",
  toggleBackground: "transparent", 
  toggleBackgroundHover: "transparent", 

  buttonColor: "#00887a",
  buttonTextColor: "#fff",
  buttonHoverColor: "#00b3a0",
  buttonHoverTextColor: "#fff",

  headerBackgroundColor: "#00887a",
  headerTextColor: "#fff",
  headerHighlightColor: "#EC008C",
  
  // BDDAB1
  filtersBackgroundColor: "#F2EFE9",
  filtersTextColor: "#1d1e25",
  filtersCheckboxBorderColor: "#00887a",
  filtersCheckboxOutlineColor: "#00887a",
  filtersCheckboxCheckmarkColor: "#00887a",
  filtersNoResultsTextColor: "#ff0000",

  footerBackgroundColor: "#00887a",
  footerColor: "#fff",
  footerHighlightColor: "yellow",

  linkColor: "#1d1e25",
  linkHoverColor: "#00887a",

  stateBorderColor: "#ddd",
  stateBoxShadow: "5px 5px 5px rgb(0 0 0 / 0.2)",

  searchBoxTextColor: "#000",
  searchBoxBackgroundColor: "#fff",
  searchBoxFocusColor: "#00887a",
  searchBoxIcon: "/static/images/search.png",

  leafletTilePaneFilter: "none",

  chartLegendTextColor: "#1d1e25",
  chartGridColor: "#ddd",
  chartCrosshairColor: "#888",

  leafletPopupBorderColor: "#fff"
}
  
// 19543E
// 1d1e25
// 004D57
// 003b43
// 212121
export const darkTheme = {
  backgroundColor: "#0D1117", 
  color: "#cacaca",

  popupLinkColor: "#fff",
  popupLinkHoverColor: "yellow",

  toggleBorder: "transparent",
  toggleBackground: "transparent", 
  toggleBackgroundHover: "transparent", 
  
  buttonColor: "#004953",
  buttonTextColor: "#fff",
  buttonHoverColor: "#007080",
  buttonHoverTextColor: "#fff",

  headerBackgroundColor: "#004953",
  headerTextColor: "#cacaca",
  headerHighlightColor: "#0D1117",
  
  filtersBackgroundColor: "#0D1117",
  filtersTextColor: "#cacaca",
  filtersCheckboxBorderColor: "#cacaca",
  filtersCheckboxOutlineColor: "#cacaca",
  filtersCheckboxCheckmarkColor: "#004953",
  filtersNoResultsTextColor: "#cacaca",

  footerBackgroundColor: "#004953",
  footerColor: "#cacaca",
  footerHighlightColor: "yellow",

  linkColor: "#cacaca",
  linkHoverColor: "yellow",
  
  stateBorderColor: "#222",
  stateBoxShadow: "5px 5px 5px rgb(75 75 75 / 0.2)",

  searchBoxTextColor: "#cacaca",
  searchBoxBackgroundColor: "#0D1117",
  searchBoxFocusColor: "#cacaca",
  searchBoxIcon: "/static/images/search-white.png",

  leafletTilePaneFilter: "invert(1) hue-rotate(180deg);",

  chartLegendTextColor: "#cacaca",
  chartGridColor: "#383a47",
  chartCrosshairColor: "#888",

  leafletPopupBorderColor: "#444"
}
