import React, { useState, useEffect } from "react";
import ufo from "../icons/ufo-3.png";
import ghost from "../icons/ghost.png";
import dogman from "../icons/dogman-3.png";
import bigfoot from "../icons/bigfoot-3.png";
import grave from "../icons/grave.png";
import sign from "../icons/sign-3.png";
import { Helmet } from "react-helmet";

const States = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const GetStateCounts = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "/api/state-counts",
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          //console.log("GetStateCounts");
          setData(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("GetStateCountsError", error);
        });
    };

    GetStateCounts();
  }, []);

  return (
    <div className="states-page">
      <Helmet>
        <title>Browse States - MapSquatch</title>
      </Helmet>

      <div className="states">
        <h1>Browse States</h1>
        {error !== "" ? (
          <div className="error">{error}</div>
        ) : data ? (
          <>
            <p className="description">
              Think your state is the king of oddities? Ever wonder how your
              favorite state ranks in the strange, unexplained and macabre?
              Think you live in a state with more ghostly activity than others?
              Maybe you think you live in the UFO mecca. Or is bigfoot activity
              at a all-time high in your neck of the woods?
            </p>
            <p className="description">
              MapSquatch provides stats on how many bigfoot sightings, dogman
              sightings, ufo sightings, haunted places, famous graves and
              offbeat attractions each state has, and how it ranks relative to
              all other states. Choose a state to see statistics and graphs on
              how your state stacks up in these categories. You just might be
              surprised.
            </p>
            <ul>
              {data.map((state, index) => {
                return (
                  <li key={index}>
                    <h2>
                      <a href={"/state/" + state.state_abbrev}>{state.state}</a>
                    </h2>
                    <div className="counts">
                      {state.counts.map((poi, index) => {
                        return (
                          <div className="poi" key={index}>
                            <div className="image">
                              <img
                                src={
                                  poi.type_short === "ufo"
                                    ? ufo
                                    : poi.type_short === "dm"
                                    ? dogman
                                    : poi.type_short === "bf"
                                    ? bigfoot
                                    : poi.type_short === "g"
                                    ? grave
                                    : poi.type_short === "ota"
                                    ? sign
                                    : ghost
                                }
                                alt={poi.type + "s"}
                              />
                            </div>
                            <div className="count">
                              ({poi.count.toLocaleString("en-US")})
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default States;
