import styled from "styled-components";

export const FilterButton = styled.button`
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;

  svg {
    margin-top: 0;
  }

  svg rect {
    fill: #fff;
  }

  :hover {
    /* border-color: #fff; */
  }

  :hover svg rect {
    fill: yellow;
  }
`;
