import React, { useState, useEffect, useRef } from "react";
import * as L from "leaflet";
import ufo from "../icons/ufo-3.png";
import ghost from "../icons/ghost.png";
import dogman from "../icons/dogman-3.png";
import bigfoot from "../icons/bigfoot-3.png";
import grave from "../icons/grave.png";
import sign from "../icons/sign-3.png";
import mixed from "../icons/mixed-8.png";
import normal from "../icons/marker-icon.png";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  FeatureGroup,
} from "react-leaflet";
import { divIcon } from "leaflet";

const MapComponent = (props) => {
  const [map, setMap] = useState();
  const ref = useRef(null);

  const darkThemeAttribution =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
  const darkThemeUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  /*
  const darkThemeAttribution =
    '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
  const darkThemeUrl =
    "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";

  const lightThemeAttribution =
    'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.';
  const lightThemeUrl =
    "https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg";
  */

  const lightThemeAttribution =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
  const lightThemeUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  let autopan = false;
  let move_start = Date.now();

  function getCustomIcon(icon, count, class_name) {
    return divIcon({
      iconSize: new L.Point(32, 20),
      html:
        '<div class="multi-icon ' +
        class_name +
        '"><img src="' +
        icon +
        '">' +
        (count > 1
          ? '<div class="count">' + (count > 9 ? "9+" : count) + "</div>"
          : "") +
        "</div>",
    });
  }

  function truncate(str, num_chars) {
    if (str) {
      return str.length > num_chars ? str.substring(0, num_chars) + "..." : str;
    }
    return "";
  }

  function getIcon(type, count) {
    switch (type) {
      case "Haunted Place":
        return getCustomIcon(ghost, count, "");
      case "Bigfoot Sighting":
        return getCustomIcon(bigfoot, count, "");
      case "Dogman Sighting":
        return getCustomIcon(dogman, count, "");
      case "UFO Sighting":
        return getCustomIcon(ufo, count, "");
      case "Famous Grave":
        return getCustomIcon(grave, count, "");
      case "Offbeat Tourist Attraction":
        return getCustomIcon(sign, count, "");
      case "normal":
        return getCustomIcon(normal, count, "normal-icon");
      default:
        return getCustomIcon(mixed, count, "");
    }
  }

  useEffect(() => {
    // We want to fit the bounds of the map to the points, so
    // turn off the exhaustive dependency warning, because we need to make sure this is a search
    // and we need to use the map.  Since this runs whenever the points change, fitBounds can
    // run here and it only run the first time a search is performed and points are returned.
    if (
      props.search &&
      props.search !== "" &&
      props.points &&
      props.points.length > 0
    ) {
      map.target.flyToBounds(getBoundingBox(props.points), {
        animate: true,
        duration: 0.4,
      });
    }
  }, [props.points]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // When theme changes, update the tile layer of the map
    if (ref.current) {
      ref.current.setUrl(props.theme === "dark" ? darkThemeUrl : lightThemeUrl);
      ref.current.attribution =
        props.theme === "dark" ? darkThemeAttribution : lightThemeAttribution;
    }
  }, [props.theme]);

  function getBoundingBox(points) {
    // Gets the bounding box of a given set of points so the map can fit the bounds
    let lats = [];
    let lngs = [];

    points.forEach((marker) => {
      lats.push(marker.latlng.split(" ")[1]);
      lngs.push(marker.latlng.split(" ")[0]);
    });

    return [
      [Math.min(...lats), Math.min(...lngs)],
      [Math.max(...lats), Math.max(...lngs)],
    ];
  }

  function RenderPoints(props) {
    return props.points !== null && props.points.length > 0 ? (
      <FeatureGroup>
        {props.points.map((marker, index) => {
          return (
            <Marker
              position={[
                marker.latlng.split(" ")[1],
                marker.latlng.split(" ")[0],
              ]}
              key={index}
              icon={getIcon(marker.type, marker.details.length)}
            >
              <Popup>
                {marker.details.length > 1 ? (
                  <div className="map-details">
                    <div className="title">
                      {marker.details.length} {marker.type}
                      {marker.type.toLowerCase() !== "various oddities"
                        ? "s"
                        : null}{" "}
                      in this area
                    </div>
                    {marker.details[0].location ? (
                      <div className="location">
                        {marker.details[0].location}
                      </div>
                    ) : null}
                    {marker.details[0].city ? (
                      <div className="city">
                        {marker.details[0].city +
                          ", " +
                          marker.details[0].state}
                      </div>
                    ) : null}
                    {marker.type.toLowerCase() !== "famous grave" ? (
                      <div className="summary">
                        There have been multiple reports of activity at this
                        location. Click details to see more info.
                      </div>
                    ) : null}
                    <div className="more">
                      <a
                        href={
                          "/details/" +
                          marker.latlng.split(" ")[1] +
                          "/" +
                          marker.latlng.split(" ")[0] +
                          "/" +
                          marker.type.replaceAll(" ", "-")
                        }
                      >
                        Details
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="map-details">
                    <div className="title">{marker.details[0].title}</div>
                    {marker.details[0].title !== marker.details[0].type ? (
                      <div className="type">{marker.type}</div>
                    ) : null}
                    {marker.details[0].location ? (
                      <div className="location">
                        {marker.details[0].location}
                      </div>
                    ) : null}
                    {marker.details[0].city ? (
                      <div className="city">
                        {marker.details[0].city +
                          ", " +
                          marker.details[0].state}
                      </div>
                    ) : null}

                    {marker.details[0].sighting_date ? (
                      <div className="date">
                        {marker.details[0].sighting_date}
                      </div>
                    ) : null}

                    <div className="summary">
                      {truncate(marker.details[0].summary, 200)}
                    </div>
                    <div className="more">
                      <a
                        href={
                          "/details/" +
                          marker.latlng.split(" ")[1] +
                          "/" +
                          marker.latlng.split(" ")[0] +
                          "/" +
                          marker.type.replaceAll(" ", "-")
                        }
                      >
                        Details
                      </a>
                    </div>
                  </div>
                )}
              </Popup>
            </Marker>
          );
        })}
      </FeatureGroup>
    ) : props.showCenterMarker ? (
      <Marker
        position={[props.clat, props.clng]}
        icon={getIcon(props.markerIcon, props.markerCount)}
      ></Marker>
    ) : null;
  }

  function SetMapEvents() {
    const map = useMapEvents({
      moveend: () => {
        // Only refresh the points if this wasn't an autopan.
        // Autopans happen when the popup won't fit on the current map and it has to
        // pan to show the entire popup.  If we refresh the points at this point, the popup
        // closes and points refresh (and the same point could possibly not even be shown again).
        if (!autopan) {
          if (props.handleMapMoveEnd) {
            // In order to save some processing time with api calls for every single zoom/move,
            // wait 200ms to see if another move/zoom starts within 200ms.  If so, it won't make an api call
            // to get the points, because they will just get overwritten anyway.
            setTimeout(() => {
              if (Date.now() - move_start > 200) {
                //console.log("zoom ended");

                // Get the new set of points
                props.handleMapMoveEnd(
                  map.getCenter(),
                  map.getBounds(),
                  map.getZoom(),
                  props.search
                );
              }
            }, 200);
          }
        }

        // Reset the autopan flag
        autopan = false;
      },
      autopanstart: () => {
        autopan = true;
      },
      movestart: () => {
        move_start = Date.now();
      },
      zoomstart: () => {
        move_start = Date.now();
      },
    });

    return null;
  }

  return (
    <div className="map">
      <div id="map">
        <MapContainer
          center={[props.clat, props.clng]}
          zoom={props.zoom}
          scrollWheelZoom={props.scrollWheelZoom}
          whenReady={(map) => {
            setMap(map);
            if (props.handleMapMoveEnd) {
              props.handleMapMoveEnd(
                map.target.getCenter(),
                map.target.getBounds(),
                map.target.getZoom()
              );
            }
          }}
        >
          <TileLayer
            ref={ref}
            attribution={
              props.theme === "dark"
                ? darkThemeAttribution
                : lightThemeAttribution
            }
            url={props.theme === "dark" ? darkThemeUrl : lightThemeUrl}
          />
          <SetMapEvents />
          <RenderPoints
            clat={props.clat}
            clng={props.clng}
            points={props.points}
            showCenterMarker={props.showCenterMarker}
            markerIcon={props.markerIcon}
            markerCount={props.markerCount}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default MapComponent;
