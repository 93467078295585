import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MapComponent from "./MapComponent";
import { Helmet } from "react-helmet";

const Details = ({ theme }) => {
  const { lat, lng, type } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [centerMarkerType, setCenterMarkerType] = useState("mixed");
  const [centerMarkerCount, setCenterMarkerCount] = useState(1);

  useEffect(() => {
    const GetInfo = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT +
          "/api/details?lat=" +
          lat +
          "&lng=" +
          lng +
          "&type=" +
          type,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          //console.log("GetInfo");
          // Get a count of the unique types in the details to determine which marker to display
          const unique_types = [...new Set(data.map((item) => item.type))];

          if (unique_types.length === 1) {
            setCenterMarkerType(unique_types[0]);
          } else {
            setCenterMarkerType("mixed");
          }

          // Set the count of records for the marker count number
          setCenterMarkerCount(data.length);

          setData(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("InfoDataError", error);
        });
    };

    GetInfo();
  }, [lat, lng, type]);

  return (
    <div className="details">
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>MapSquatch Oddity Details</title>
      </Helmet>

      <div className="content">
        <h1>Details</h1>
        <div className="latlng">
          ({lat} {lng})
        </div>
        <div className="back">
          <a href="/" className="button">
            Back to Map
          </a>
        </div>
      </div>

      <MapComponent
        clat={lat}
        clng={lng}
        zoom={14}
        points={null}
        theme={theme}
        scrollWheelZoom={false}
        showCenterMarker={true}
        markerIcon={centerMarkerType}
        markerCount={centerMarkerCount}
      />

      <div className="content">
        {error === "" ? (
          data != null ? (
            <>
              {data.map((point, idx) => {
                return (
                  <div className="poi" key={idx}>
                    <h2>{point.type}</h2>

                    {point.title !== point.type ? <h3>{point.title}</h3> : null}

                    {point.location ? (
                      <div className="location">{point.location}</div>
                    ) : null}

                    {point.sighting_date ? (
                      <div className="date">{point.sighting_date}</div>
                    ) : null}

                    <div className="bf-stats">
                      {point.season ? (
                        <div className="season">
                          <span className="caption">Season:</span>{" "}
                          {point.season}
                        </div>
                      ) : null}

                      {point.classification ? (
                        <div className="classification">
                          <span className="caption">Classification:</span>{" "}
                          {point.classification}
                        </div>
                      ) : null}

                      {point.location_description ? (
                        <div className="location_desc">
                          {point.location_description}
                        </div>
                      ) : null}
                    </div>

                    <div className="ufo-stats">
                      {point.shape ? (
                        <div className="shape">
                          <span className="caption">Shape:</span> {point.shape}
                        </div>
                      ) : null}

                      {point.duration ? (
                        <div className="duration">
                          <span className="caption">Duration:</span>{" "}
                          {point.duration}
                        </div>
                      ) : null}

                      {point.stats ? (
                        <div className="stats">
                          <span className="caption">Stats:</span> {point.stats}
                        </div>
                      ) : null}
                    </div>

                    <div className="summary">
                      {(point.summary + "").split("|").map((line, idx2) => {
                        return (
                          <div className="line" key={idx2}>
                            {line}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="loading">Loading info...</div>
          )
        ) : (
          <div className="error">{error}</div>
        )}
      </div>
    </div>
  );
};

export default Details;
