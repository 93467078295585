import styled from "styled-components";

const StyledSearchBox = styled.div`
  display: flex;
  position: relative;

  input[type="text"] {
    border: none;
    margin: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.searchBoxTextColor};
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.searchBoxFocusColor};
    width: 100%;
    padding: 9px 35px 9px 35px;
    background: ${({ theme }) => theme.searchBoxBackgroundColor}
      url(${({ theme }) => theme.searchBoxIcon}) no-repeat 10px center;
    background-size: 15px 15px;
  }

  input[type="text"]::placeholder {
    color: #bbb;
  }

  input[type="text"]:focus {
    box-shadow: 0 0 3px 0 ${({ theme }) => theme.searchBoxFocusColor};
    border-color: ${({ theme }) => theme.searchBoxFocusColor};
    outline: none;
  }

  button {
    border: 0;
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    top: 13px;
    right: 15px;
    padding: 0;
    width: 15px;
    height: 15px;

    img {
      width: 15px;
      height: 15px;
    }
  }
`;

export { StyledSearchBox };
