import React from "react";
import { func, string } from "prop-types";
import moon from "../icons/moon-2.png";
import sun from "../icons/sun-2.png";
import { ThemeButton } from "./styled/ThemeButton";

const ToggleTheme = ({ theme, toggleTheme }) => {
  const isLight = theme === "light";

  return (
    <ThemeButton onClick={toggleTheme} isLight={isLight}>
      <img src={sun} alt="Use dark mode" />
      <img src={moon} alt="Use light mode" />
    </ThemeButton>
  );
};

ToggleTheme.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};

export default ToggleTheme;
