import React from "react";

const FAQ = () => {
  return (
    <div className="faq">
      <h1>MapSquatch.com FAQ</h1>

      <h2>What is the purpose of this site?</h2>
      <p>
        MapSquatch.com is just for fun. Avid fans of the paranormal and
        cryptozoology will find it interesting to be able to see points of
        interest on a map and be able to filter and search for what they are
        interested in.
      </p>

      <h2>Where does the data on this site come from?</h2>
      <p>
        MapSquatch.com uses publicly available datasets of Bigfoot sightings,
        Dogman sightings, UFO sightings, haunted places, famous graves and
        offbeat tourist attractions. Some data has been cleaned using various
        geo-coding techniques, as well as manual data manipulation so that the
        information can be as accurate as possible. There will, of course, be
        inaccuracies.
      </p>

      <h2>How accurate is this data?</h2>
      <p>
        Much of the information listed on this site is unsubstatiated, so take
        from it what you will. Again, it's only for fun. The coordinates listed
        for each point of interest also may or may not be accurate. Some of the
        coordinates are general coordinates of the closest town or city, since
        no exact coordinates could be found. In no way does MapSquatch.com
        declare that any data on the site is accurate.
      </p>

      <h2>Can I investigate these points of interest?</h2>
      <p>
        Many of the points of interest are on private property. Since
        MapSquatch.com does not have information about whether the locations are
        publically accessible, you should always verify that a location can
        legally be entered prior to going there. MapSquatch.com is not
        responsible for any illegal activity resulting from the information on
        this site. Please give landowners the proper respect and always ask
        permission before going onto any property. MapSquatch.com isn't
        responsible for the accuracy or claims of any of the data. Please verify
        any claims of public accessibility with the landowner. Also, the
        locations could be dangerous and/or impossible to safely get to, so
        MapSquatch.com assumes no responsibility of any harm/injuries resulting
        from any information given on this site. Be safe when squatchin'.
      </p>

      <h2>How do you determine what points to display?</h2>
      <p>
        Assuming you are not using the search box, MapSquatch.com selects up to
        200 points of interest that lie in the visible area of the map and
        displays them. This means that if you are zoomed out, you will not see
        all of the points of interest until you zoom in more. This is to keep
        the site quick and usable. The types of points shown depends on the
        filter that you have selected.
      </p>

      <h2>How does search work?</h2>
      <p>
        Searching on MapSquatch.com searches the title, location, and
        description of the point of interest, across the entire map (not just
        the current map view). Upon finding results, it will display all
        matches, up to 200, on the map and fit the map to include all results.
        You can also filter the type of point of interest that you want to
        search for. To cancel a search, simply tap the "X" inside the search
        box.
      </p>
    </div>
  );
};

export default FAQ;
