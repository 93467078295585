import React, { useState } from "react";
import { useCookies } from "react-cookie";
import FilterBlock from "./FilterBlock";
import MapComponent from "./MapComponent";

const MapPage = (props) => {
  const [cookies, setCookie] = useCookies([
    "bigfoot",
    "hauntedplaces",
    "dogman",
    "ufo",
    "famousgraves",
    "offbeattouristattractions",
    "centerlat",
    "centerlng",
    "zoom",
  ]);

  const [filters, setFilters] = useState({
    bigfoot: cookies.bigfoot !== "false",
    hauntedplaces: cookies.hauntedplaces !== "false",
    dogman: cookies.dogman !== "false",
    ufo: cookies.ufo !== "false",
    famousgraves: cookies.famousgraves !== "false",
    offbeattouristattractions: cookies.offbeattouristattractions !== "false",
  });
  const [searchTerms, setSearchTerms] = useState("");
  const [points, setPoints] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);

  var cookie_expiration = new Date();
  var clat = cookies.centerlat;
  var clng = cookies.centerlng;
  var zoom = cookies.zoom;

  cookie_expiration.setDate(cookie_expiration.getDate() + 30);

  // Set some defaults if the cookies are empty
  if (zoom === undefined) {
    zoom = 5;
  }

  // If the cookies aren't set, default to the middle of the US.
  if (clat === undefined || clng === undefined) {
    clat = 37.23;
    clng = -93.91;
  }

  const handleSearchTermsChanged = (terms) => {
    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        filters.bigfoot,
        filters.hauntedplaces,
        filters.dogman,
        filters.ufo,
        filters.famousgraves,
        filters.offbeattouristattractions,
        terms
      );
    }

    setSearchTerms(terms);
  };

  const handleBigfootChange = () => {
    setCookie("bigfoot", !filters.bigfoot, {
      path: "/",
      expires: cookie_expiration,
    });

    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        !filters.bigfoot,
        filters.hauntedplaces,
        filters.dogman,
        filters.ufo,
        filters.famousgraves,
        filters.offbeattouristattractions,
        searchTerms
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      bigfoot: !prevFilters.bigfoot,
    }));
  };

  const handleHauntedPlacesChange = () => {
    setCookie("hauntedplaces", !filters.hauntedplaces, {
      path: "/",
      expires: cookie_expiration,
    });

    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        filters.bigfoot,
        !filters.hauntedplaces,
        filters.dogman,
        filters.ufo,
        filters.famousgraves,
        filters.offbeattouristattractions,
        searchTerms
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      hauntedplaces: !prevFilters.hauntedplaces,
    }));
  };

  const handleFamousGravesChange = () => {
    setCookie("famousgraves", !filters.famousgraves, {
      path: "/",
      expires: cookie_expiration,
    });

    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        filters.bigfoot,
        filters.hauntedplaces,
        filters.dogman,
        filters.ufo,
        !filters.famousgraves,
        filters.offbeattouristattractions,
        searchTerms
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      famousgraves: !prevFilters.famousgraves,
    }));
  };

  const handleDogmanChange = () => {
    setCookie("dogman", !filters.dogman, {
      path: "/",
      expires: cookie_expiration,
    });

    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        filters.bigfoot,
        filters.hauntedplaces,
        !filters.dogman,
        filters.ufo,
        filters.famousgraves,
        filters.offbeattouristattractions,
        searchTerms
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      dogman: !prevFilters.dogman,
    }));
  };

  const handleUfoChange = () => {
    setCookie("ufo", !filters.ufo, { path: "/", expires: cookie_expiration });

    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        filters.bigfoot,
        filters.hauntedplaces,
        filters.dogman,
        !filters.ufo,
        filters.famousgraves,
        filters.offbeattouristattractions,
        searchTerms
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      ufo: !prevFilters.ufo,
    }));
  };

  const handleOffbeatTouristAttractionsChange = () => {
    setCookie("offbeattouristattractions", !filters.offbeattouristattractions, {
      path: "/",
      expires: cookie_expiration,
    });

    if (mapCenter != null) {
      getPoints(
        mapCenter,
        mapBounds,
        mapZoom,
        filters.bigfoot,
        filters.hauntedplaces,
        filters.dogman,
        filters.ufo,
        filters.famousgraves,
        !filters.offbeattouristattractions,
        searchTerms
      );
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      offbeattouristattractions: !prevFilters.offbeattouristattractions,
    }));
  };

  const getPoints = (
    center,
    bounds,
    zoom,
    bigfoot,
    haunted_places,
    dogman,
    ufo,
    famousgraves,
    offbeattouristattractions,
    search_terms
  ) => {
    console.log("getPoints", search_terms);
    // Get the bound coords
    const neLat = bounds.getNorthEast().lat;
    const neLng = bounds.getNorthEast().lng;
    const nwLat = bounds.getNorthWest().lat;
    const nwLng = bounds.getNorthWest().lng;
    const seLat = bounds.getSouthEast().lat;
    const seLng = bounds.getSouthEast().lng;
    const swLat = bounds.getSouthWest().lat;
    const swLng = bounds.getSouthWest().lng;

    // Get the point types
    var types =
      (bigfoot ? "bf|" : "") +
      (haunted_places ? "hp|" : "") +
      (dogman ? "dm|" : "") +
      (ufo ? "ufo|" : "") +
      (famousgraves ? "g|" : "") +
      (offbeattouristattractions ? "ota|" : "");

    // Create the request
    const requestOptions = {
      method: "GET",
    };

    const data =
      "nelat=" +
      neLat +
      "&nelng=" +
      neLng +
      "&nwlat=" +
      nwLat +
      "&nwlng=" +
      nwLng +
      "&selat=" +
      seLat +
      "&selng=" +
      seLng +
      "&swlat=" +
      swLat +
      "&swlng=" +
      swLng +
      "&types=" +
      types +
      "&search=" +
      search_terms +
      "&zoom=" +
      zoom;

    // Get the search results
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        process.env.REACT_APP_MAP_POINT_ENDPOINT +
        "?" +
        data,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          console.log("Something went wrong with fetching points");
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        // Set the points in the state
        setPoints(data);
      })
      .catch((error) => {
        console.log("error", error);
      });

    //document.title = "Search results for " + terms;
  };

  const handleMapMoveEnd = (center, bounds, zoom, search_terms) => {
    setMapCenter(center);
    setMapBounds(bounds);
    setMapZoom(zoom);

    // The map moved, so save the cookies
    setCookie("centerlat", center.lat, {
      path: "/",
      expires: cookie_expiration,
    });
    setCookie("centerlng", center.lng, {
      path: "/",
      expires: cookie_expiration,
    });
    setCookie("zoom", zoom, { path: "/", expires: cookie_expiration });

    if (search_terms === undefined || search_terms === "") {
      getPoints(
        center,
        bounds,
        zoom,
        filters.bigfoot,
        filters.hauntedplaces,
        filters.dogman,
        filters.ufo,
        filters.famousgraves,
        filters.offbeattouristattractions,
        searchTerms
      );
    }
  };

  return (
    <div className="main">
      <FilterBlock
        filters={filters}
        handleBigfootChange={handleBigfootChange}
        handleHauntedPlacesChange={handleHauntedPlacesChange}
        handleDogmanChange={handleDogmanChange}
        handleUfoChange={handleUfoChange}
        handleFamousGravesChange={handleFamousGravesChange}
        handleOffbeatTouristAttractionsChange={
          handleOffbeatTouristAttractionsChange
        }
        handleSearchTermsChanged={handleSearchTermsChanged}
        filtersToggleEvent={props.filtersToggleEvent}
        searchResultsCount={points ? points.length : -1}
        theme={props.theme}
        toggleFilters={props.toggleFilters}
      />
      <MapComponent
        clat={clat}
        clng={clng}
        zoom={zoom}
        scrollWheelZoom={true}
        showCenterMarker={false}
        points={points}
        theme={props.theme}
        search={searchTerms}
        handleMapMoveEnd={handleMapMoveEnd}
      />
    </div>
  );
};

export default MapPage;
