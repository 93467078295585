import React from "react";
import { func, string } from "prop-types";
import ToggleTheme from "./ToggleTheme";
import bigfoot from "../icons/bigfoot-3.png";

const Header = ({ theme, toggleTheme, toggleFilters, filtersOn }) => {
  return (
    <header>
      <div className="logo-tagline">
        <div className="logo">
          <a href="/">
            <img src={bigfoot} alt="MapSquatch" />
            <h1>MapSquatch</h1>
          </a>
        </div>
        <div className="tagline">Mapping Bigfoot and other oddities</div>
      </div>
      <div className="controls">
        <div className="mode">
          <ToggleTheme theme={theme} toggleTheme={toggleTheme} />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};

export default Header;
