import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  .app {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
    transition: all 0.20s linear;
  }
  
  .details {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
  }

  .details .poi { border-bottom: 1px solid ${({ theme }) => theme.color}; }
  .details a { color: ${({ theme }) => theme.linkColor}; }
  .details a:hover { color: ${({ theme }) => theme.linkHoverColor}; }

  .states-page {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
  }

  .states-page a { color: ${({ theme }) => theme.linkColor}; }
  .states-page a:hover { color: ${({ theme }) => theme.linkHoverColor}; }
  .states-page .states ul li {
    border: 1px solid ${({ theme }) => theme.stateBorderColor};
    box-shadow: ${({ theme }) => theme.stateBoxShadow};
  }

  .state-page {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
  }

  .state-page a { color: ${({ theme }) => theme.linkColor}; }
  .state-page a:hover { color: ${({ theme }) => theme.linkHoverColor}; }

  .state-page .state h2 {
    border-bottom: 1px solid ${({ theme }) => theme.chartGridColor};
    padding-bottom: 5px;
  }

  .main .map .more a { color: ${({ theme }) => theme.popupLinkColor}; }
  .main .map .more a:hover {
    color: ${({ theme }) => theme.popupLinkHoverColor};
  }

  .leaflet-tile-pane { filter: ${({ theme }) => theme.leafletTilePaneFilter}; }

  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
  }

  #map .leaflet-marker-icon .count {
    background-color: ${({ theme }) => theme.headerBackgroundColor};
    color:  ${({ theme }) => theme.headerTextColor};
  }

  .page-not-found h1, .page-not-found h2 {
    color: ${({ theme }) => theme.color};
  }
  .page-not-found a { color: ${({ theme }) => theme.linkColor}; }
  .page-not-found a:hover { color: ${({ theme }) => theme.linkHoverColor}; }

  .credits a { color: ${({ theme }) => theme.linkColor}; }
  .credits a:hover { color: ${({ theme }) => theme.linkHoverColor}; }

  header {
    background-color: ${({ theme }) => theme.headerBackgroundColor};
    color: ${({ theme }) => theme.headerTextColor};
  }

  header a { color: ${({ theme }) => theme.headerTextColor}; }
  header a:hover { color: ${({ theme }) => theme.headerHighlightColor}; }

  .main .filter-block-wrapper {
    background-color: ${({ theme }) => theme.filtersBackgroundColor};
    color: ${({ theme }) => theme.filtersTextColor};
    position: absolute;
    z-index: 2000;
    width: 100%;
  }

  .main .filter-block { 
    border-bottom: 4px solid ${({ theme }) => theme.headerBackgroundColor};
  }

  .filter-block-wrapper .toggle {
    background-color: ${({ theme }) => theme.headerBackgroundColor}
  }

  .main .filter-block .no-results { color: ${({ theme }) =>
    theme.filtersNoResultsTextColor}; }

  @media (min-width: 640px) {
    .main .filter-block-wrapper {
      border-right: 4px solid ${({ theme }) => theme.headerBackgroundColor};
      border-bottom: 0;
      display: block;
      position: relative;
      width: auto;
    }
    .main .filter-block { border-bottom: 0; }
  }

  input[type="checkbox"] {
    border-color: ${({ theme }) => theme.filtersCheckboxBorderColor};
  }

  input[type="checkbox"]::before {
    box-shadow: inset 1em 1em ${({ theme }) =>
      theme.filtersCheckboxCheckmarkColor};
  }

  .checkbox-control:focus-within {
    outline: 2px dotted ${({ theme }) => theme.filtersCheckboxOutlineColor};
    outline-offset: 2px;
  }

  footer {
    background-color: ${({ theme }) => theme.footerBackgroundColor};
    color: ${({ theme }) => theme.footerColor};
  }

  footer a { color: ${({ theme }) => theme.footerColor}; }
  footer a:hover { color: ${({ theme }) => theme.footerHighlightColor}; }

  a.button { 
    padding: 10px;
    text-align: center;
    display: inline-block;
    border-radius: 4px; 
    text-decoration: none;
    background-color: ${({ theme }) => theme.buttonColor};
    color: ${({ theme }) => theme.buttonTextColor};
  }

  a.button:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.buttonHoverColor};
    color: ${({ theme }) => theme.buttonHoverTextColor};
  }

  #map .leaflet-popup-content-wrapper,
  #map .leaflet-popup-tip { border: 1px solid ${({ theme }) =>
    theme.leafletPopupBorderColor}; }
`;
