import React, { useState, useEffect } from "react";
import { getState } from "../Helpers";

export const Cities = ({ state }) => {
  const [error, setError] = useState("");
  const [cities, setCities] = useState(null);
  const full_state = getState(state);

  useEffect(() => {
    const GetCities = () => {
      const requestOptions = {
        method: "get",
      };

      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "/api/cities?state=" + state,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setError(
              "There was an error retrieving the info. Please try again later."
            );
            console.log("CitiesDataError", response);
          }
          return response;
        })
        .then((resp) => resp.json())
        .then((data) => {
          setCities(data);

          //console.log(data);
        })
        .catch((error) => {
          setError(
            "There was an error retrieving the info. Please try again later."
          );
          console.log("CitiesDataError", error);
        });
    };

    GetCities();
  }, [state]);

  return (
    <div className="cities">
      {error !== "" ? (
        <div className="error">{error}</div>
      ) : (
        <>
          <h2>Cities/Towns in {full_state} with Oddities</h2>
          {cities ? (
            <>
              <ul>
                {cities.map((city, index) => {
                  return (
                    <li key={index}>
                      <a href={"/" + state + "/city/" + city.city}>
                        {city.city}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Cities;
